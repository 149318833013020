import styled from "styled-components";
import { IcOutlinkM } from "../../icons";
const GnbMobile = () => (
  <StyledWrap>
    <StyledMenuContents>
      <StyledMenuContentsItem>
        <StyledTitle>협회소개</StyledTitle>
        <StyledMenu>
          <StyledMenuItem href="/introduce">설립 취지</StyledMenuItem>
          <StyledMenuItem href="/introduce#executives">
            회장 및 임원사
          </StyledMenuItem>
          <StyledMenuItem href="/introduce#organizationLocation">
            조직도
          </StyledMenuItem>
          <StyledMenuItem href="/introduce#organizationLocation">
            오시는 길
          </StyledMenuItem>
        </StyledMenu>
      </StyledMenuContentsItem>

      <StyledMenuContentsItem>
        <StyledTitle>주요사업</StyledTitle>
        <StyledMenu>
          <StyledMenuItem href="/business">
            위조품 유통방지 협의회
          </StyledMenuItem>
          <StyledMenuItem href="/business#businessArea2">
            패션 브랜드 법률 지원
          </StyledMenuItem>
          <StyledMenuItem href="/business#businessArea3">
            정책 전문가 네트워크
          </StyledMenuItem>
          <StyledMenuItem href="/business#businessArea4">
            브랜드 패션 산업계 조사 분석
          </StyledMenuItem>
        </StyledMenu>
      </StyledMenuContentsItem>

      <StyledMenuContentsItem>
        <StyledTitle>소식</StyledTitle>
        <StyledMenu>
          <StyledMenuItem href="/notice">공지사항</StyledMenuItem>
          <StyledMenuItem href="/press">보도자료</StyledMenuItem>
          <StyledMenuItem href="/news">지식재산권 뉴스</StyledMenuItem>
        </StyledMenu>
      </StyledMenuContentsItem>

      <StyledMenuContentsItem>
        <StyledTitle>함께하는 곳</StyledTitle>
        <StyledMenu>
          <StyledMenuItem href="/memberList">회원사 리스트</StyledMenuItem>
          <StyledMenuItem href="/memberNews">회원사 동향</StyledMenuItem>
          <StyledMenuItem
            href="//docs.google.com/forms/d/e/1FAIpQLSeS2WZ7G5Sn0N4wYNG7PYFFjGMuoxoJPeLqsu_lNLEOjYRdEw/viewform"
            target="_blank"
            rel="noopener noreferrer"
          >
            협회 가입안내
            <IcOutlinkM color="#AAB4BC" />
          </StyledMenuItem>
        </StyledMenu>
      </StyledMenuContentsItem>

      <StyledLink
        href="https://fakenever.com/fakereportboard"
        target="_blank"
        rel="noopener noreferrer"
      >
        FAKE 피해사례
        <IcOutlinkM color="#dde1e4" />
      </StyledLink>
    </StyledMenuContents>
  </StyledWrap>
);

const StyledWrap = styled.nav`
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 50px;
  z-index: 100;
  background: #202124;
`;

const StyledMenuContents = styled.div`
  padding: 0 18px 36px;
`;

const StyledMenuContentsItem = styled.div`
  padding: 27px 0 13px;
  border-bottom: 1px solid #3b3f44;
`;

const StyledTitle = styled.p`
  padding-bottom: 10px;
  font-size: 18px;
  color: #ced3d8;
`;

const StyledMenu = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const StyledMenuItem = styled.a`
  display: block;
  width: 50%;
  font-size: 13px;
  color: #aab4bc;

  &:first-child,
  &:nth-child(2) {
    margin-bottom: 10px;
  }

  > svg {
    margin-left: 4px;
  }
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 42px;
  margin-top: 30px;
  font-size: 16px;
  color: #dde1e4;
  background: #95a1ac;

  > svg {
    margin-left: 4px;
  }
`;

export default GnbMobile;
