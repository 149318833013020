import React from "react";

const IcLogoM = () => {
  return (
    <svg
      width="79"
      height="53"
      viewBox="0 0 79 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60.559 39.1568H57.0566C58.1366 39.5089 58.8974 40.1942 59.2095 41.135C59.2393 41.2248 59.2634 41.3165 59.2847 41.4091H60.559V42.1795H59.3585C59.3561 42.2398 59.3539 42.3003 59.3492 42.3595C59.2805 43.2045 58.8925 43.9142 58.2244 44.4319H60.559V44.829H63.2023V36.0449H60.559V39.1568Z"
        fill="#3B3F44"
      />
      <path
        d="M78.1146 32.0959H67.0188V28.5752H64.4785V34.2095H78.1146V32.0959Z"
        fill="#3B3F44"
      />
      <path
        d="M75.5739 30.0314H78.1143V18.2803H75.5739V19.5249H72.9541V21.6029H75.5739V22.7257H72.9541V24.8037H75.5739V30.0314Z"
        fill="#3B3F44"
      />
      <path
        d="M14.347 8.53189H15.8882V6.50725H14.347V0.49707H11.8066V12.2483H14.347V8.53189Z"
        fill="#3B3F44"
      />
      <path
        d="M10.6131 1.29714H6.64782V-0.000976562H3.96515V1.29714H0V3.25071H10.6131V1.29714Z"
        fill="#3B3F44"
      />
      <path
        d="M1.06641 11.1484V16.4272H14.3469V14.3137H3.60682V11.1484H1.06641Z"
        fill="#3B3F44"
      />
      <path
        d="M6.26662 3.67969H4.34638C1.84736 3.67969 0.355469 4.93213 0.355469 7.03015C0.355469 9.12831 1.84736 10.3809 4.34638 10.3809H6.26662C8.76552 10.3809 10.2575 9.12831 10.2575 7.03015C10.2575 4.93213 8.76552 3.67969 6.26662 3.67969ZM5.92888 8.3384H4.68413C3.56429 8.3384 2.89588 7.84937 2.89588 7.03015C2.89588 6.21107 3.56429 5.72203 4.68413 5.72203H5.92888C7.06531 5.72203 7.71712 6.19886 7.71712 7.03015C7.71712 7.86157 7.06531 8.3384 5.92888 8.3384Z"
        fill="#3B3F44"
      />
      <path
        d="M22.9627 10.4371H17.4682V12.5508H28.7416V16.4982H31.282V10.4371H25.6097V8.532H32.4379V6.50736H31.282V0.568359H17.4682V2.68189H28.7416V6.50736H16.4902V8.532H22.9627V10.4371Z"
        fill="#3B3F44"
      />
      <path
        d="M20.263 32.0424V29.1084H17.7226V32.0424H0.355469V34.2093H17.7226H31.5365H32.6893L46.5529 34.2094V32.0424H32.6893H20.263Z"
        fill="#3B3F44"
      />
      <path
        d="M15.5857 18.5996H13.249V21.9959H3.40303V18.5996H1.06641V30.0307H15.5857V18.5996ZM13.249 27.8815H3.4029V24.3051H13.249V27.8815Z"
        fill="#3B3F44"
      />
      <path
        d="M24.229 25.9965H18.8233V24.1269H23.6243V18.457H16.3896V20.4639H21.1906V22.1732H16.3896V28.0033H24.229V25.9965Z"
        fill="#3B3F44"
      />
      <path
        d="M31.1808 30.2082V18.2793H28.7826V21.9956H27.3932V18.3681H24.9951V30.0304H27.3932V24.2869H28.7826V30.2082H31.1808Z"
        fill="#3B3F44"
      />
      <path
        d="M46.2567 27.8811H34.9567V20.7661H46.2567V18.6172H32.6895V30.0304H46.2567V27.8811Z"
        fill="#3B3F44"
      />
      <path
        d="M59.5075 28.2558H60.7191V34.2092H63.1172V27.9598L63.1223 27.9593C65.6606 27.7188 67.4063 26.7281 68.784 24.7448C69.1871 24.1039 69.8634 22.7464 69.8634 20.8569V20.8119H69.9388V27.9595H72.2816V18.502H68.2796L68.2752 18.5583C68.2743 18.569 68.1923 19.6214 68.1134 20.4566C67.3639 24.6586 64.232 25.6586 63.2918 25.8644L63.2211 25.8782C63.1864 25.8853 63.1519 25.892 63.1172 25.898V18.2793H60.7191V25.9644H59.5075V18.3681H57.1094V34.2092H59.5075V28.2558Z"
        fill="#3B3F44"
      />
      <path
        d="M56.5916 32.0597H55.3112V20.8728H56.5916V18.7236H47.632V20.8728H48.9834V32.0597H47.4541V34.209H56.5916V32.0597ZM52.8774 32.0597H51.4171V20.8728H52.8774V32.0597Z"
        fill="#3B3F44"
      />
      <path
        d="M68.1166 35.9844V37.3951H64.1514V39.5443H74.7645V37.3951H70.7993V35.9844H68.1166Z"
        fill="#3B3F44"
      />
      <path
        d="M70.7816 47.3625C73.0878 47.132 74.4091 45.8117 74.4091 43.733C74.4091 41.4456 72.7975 40.0801 70.0982 40.0801H68.8179C66.1186 40.0801 64.5071 41.4456 64.5071 43.733C64.5071 45.8136 65.8282 47.1339 68.1345 47.3626V49.842H63.707V52.009H75.3395V49.842H70.7816V47.3625ZM68.9781 45.2366C67.7692 45.2366 67.0474 44.6744 67.0474 43.733C67.0474 42.7914 67.7692 42.2292 68.9781 42.2292H69.9382C71.1651 42.2292 71.8687 42.7773 71.8687 43.733C71.8687 44.6886 71.1651 45.2366 69.9382 45.2366H68.9781Z"
        fill="#3B3F44"
      />
      <rect
        x="76.2256"
        y="36.0615"
        width="2.54041"
        height="16.0009"
        fill="#3B3F44"
      />
      <path
        d="M59.0882 36.8439H55.123V35.6348H52.4403V36.8439H48.1787V38.7975H59.0882V36.8439Z"
        fill="#3B3F44"
      />
      <path
        d="M60.6379 46.7306H52.142V45.4502H49.6016V52.0627H63.1782V45.4502H60.6379V46.7306ZM60.6379 50.0201H52.142V48.773H60.6379V50.0201Z"
        fill="#3B3F44"
      />
      <path
        d="M54.9324 44.9677C55.9442 44.9677 56.8246 44.7493 57.4785 44.3359C58.2188 43.8679 58.6464 43.1754 58.7152 42.3332C58.721 42.2614 58.7276 42.1796 58.7276 42.0971C58.7276 41.8011 58.682 41.5136 58.5918 41.2421C58.2089 40.0894 57.0494 39.3612 55.4108 39.2446C55.2408 39.2324 55.0843 39.2266 54.9324 39.2266H52.3294C50.0239 39.2266 48.5342 40.3533 48.5342 42.0971C48.5342 43.8409 50.0239 44.9677 52.3294 44.9677H54.9324ZM51.0746 42.0971C51.0746 41.5563 51.6102 41.2336 52.5073 41.2336H54.7545C55.6516 41.2336 56.1873 41.5563 56.1873 42.0971C56.1873 42.6379 55.6516 42.9607 54.7545 42.9607H52.5073C51.6102 42.9607 51.0746 42.6379 51.0746 42.0971Z"
        fill="#3B3F44"
      />
    </svg>
  );
};

export default React.memo(IcLogoM);
