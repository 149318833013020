import React from "react";

const IcLogo = () => {
  return (
    <svg
      width="114"
      height="75"
      viewBox="0 0 114 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M86.9225 56.2048H81.8955C83.4456 56.7103 84.5376 57.6938 84.9855 59.0442C85.0283 59.173 85.0629 59.3047 85.0935 59.4377H86.9225V60.5434H85.1994C85.196 60.63 85.1928 60.7168 85.186 60.8018C85.0873 62.0146 84.5305 63.0332 83.5715 63.7763H86.9225V64.3463H90.7166V51.7383H86.9225V56.2048Z"
        fill="#3B3F44"
      />
      <path
        d="M112.12 46.069H96.194V41.0156H92.5479V49.1028H112.12V46.069Z"
        fill="#3B3F44"
      />
      <path
        d="M108.473 43.105H112.12V26.2383H108.473V28.0247H104.713V31.0073H108.473V32.6189H104.713V35.6015H108.473V43.105Z"
        fill="#3B3F44"
      />
      <path
        d="M20.5926 12.2474H22.8046V9.34142H20.5926V0.714844H16.9463V17.5817H20.5926V12.2474Z"
        fill="#3B3F44"
      />
      <path
        d="M15.2333 1.86321H9.54179V0H5.69128V1.86321H0V4.66723H15.2333V1.86321Z"
        fill="#3B3F44"
      />
      <path
        d="M1.53125 16.002V23.5787H20.5931V20.5451H5.17757V16.002H1.53125Z"
        fill="#3B3F44"
      />
      <path
        d="M8.99517 5.2832H6.23901C2.65209 5.2832 0.510742 7.08087 0.510742 10.0922C0.510742 13.1038 2.65209 14.9016 6.23901 14.9016H8.99517C12.5819 14.9016 14.7234 13.1038 14.7234 10.0922C14.7234 7.08087 12.5819 5.2832 8.99517 5.2832ZM8.5104 11.97H6.72378C5.11645 11.97 4.15707 11.2681 4.15707 10.0922C4.15707 8.91657 5.11645 8.21464 6.72378 8.21464H8.5104C10.1416 8.21464 11.0771 8.89903 11.0771 10.0922C11.0771 11.2856 10.1416 11.97 8.5104 11.97Z"
        fill="#3B3F44"
      />
      <path
        d="M32.9591 14.9813H25.0726V18.0151H41.2537V23.6809H44.9V14.9813H36.7584V12.2468H46.5591V9.34081H44.9V0.816406H25.0726V3.85002H41.2537V9.34081H23.6689V12.2468H32.9591V14.9813Z"
        fill="#3B3F44"
      />
      <path
        d="M29.0846 45.9924V41.7812H25.4383V45.9924H0.510742V49.1027H25.4383H45.2657H46.9203L66.8192 49.1028V45.9924H46.9203H29.0846Z"
        fill="#3B3F44"
      />
      <path
        d="M22.3711 26.6973H19.0173V31.572H4.88506V26.6973H1.53125V43.1046H22.3711V26.6973ZM19.0173 40.0198H4.88489V34.8866H19.0173V40.0198Z"
        fill="#3B3F44"
      />
      <path
        d="M34.7754 37.3157H27.0166V34.6322H33.9075V26.4941H23.5234V29.3746H30.4144V31.8281H23.5234V40.1961H34.7754V37.3157Z"
        fill="#3B3F44"
      />
      <path
        d="M44.7554 43.3601V26.2383H41.3132V31.5724H39.3189V26.3657H35.877V43.105H39.3189V34.8612H41.3132V43.3601H44.7554Z"
        fill="#3B3F44"
      />
      <path
        d="M66.3933 40.0194H50.1742V29.8071H66.3933V26.7227H46.9199V43.1044H66.3933V40.0194Z"
        fill="#3B3F44"
      />
      <path
        d="M85.4128 40.5579H87.1518V49.1028H90.5939V40.1329L90.6013 40.1322C94.2446 39.7871 96.7501 38.365 98.7276 35.5184C99.3062 34.5985 100.277 32.6501 100.277 29.9379V29.8734H100.385V40.1326H103.748V26.558H98.0037L97.9973 26.6388C97.9961 26.654 97.8783 28.1646 97.7651 29.3634C96.6893 35.3947 92.194 36.8299 90.8445 37.1254L90.7431 37.1452C90.6932 37.1554 90.6437 37.165 90.5939 37.1736V26.2383H87.1518V37.269H85.4128V26.3657H81.9707V49.1028H85.4128V40.5579Z"
        fill="#3B3F44"
      />
      <path
        d="M81.2275 46.0166H79.3898V29.9598H81.2275V26.875H68.3677V29.9598H70.3073V46.0166H68.1123V49.1016H81.2275V46.0166ZM75.8965 46.0166H73.8005V29.9598H75.8965V46.0166Z"
        fill="#3B3F44"
      />
      <path
        d="M97.7695 51.6504V53.6752H92.0781V56.7599H107.311V53.6752H101.62V51.6504H97.7695Z"
        fill="#3B3F44"
      />
      <path
        d="M101.595 67.9819C104.905 67.6512 106.801 65.7561 106.801 62.7724C106.801 59.4892 104.488 57.5293 100.614 57.5293H98.7762C94.9018 57.5293 92.5887 59.4892 92.5887 62.7724C92.5887 65.7587 94.4849 67.6538 97.7953 67.9821V71.5408H91.4404V74.6512H108.137V71.5408H101.595V67.9819ZM99.0061 64.9306C97.2711 64.9306 96.235 64.1237 96.235 62.7724C96.235 61.421 97.2711 60.614 99.0061 60.614H100.384C102.145 60.614 103.155 61.4006 103.155 62.7724C103.155 64.144 102.145 64.9306 100.384 64.9306H99.0061Z"
        fill="#3B3F44"
      />
      <rect
        x="109.408"
        y="51.7617"
        width="3.64632"
        height="22.9665"
        fill="#3B3F44"
      />
      <path
        d="M84.8111 52.884H79.1196V51.1484H75.2692V52.884H69.1523V55.6881H84.8111V52.884Z"
        fill="#3B3F44"
      />
      <path
        d="M87.0341 67.0741H74.8397V65.2363H71.1934V74.7275H90.6802V65.2363H87.0341V67.0741ZM87.0341 71.7957H74.8397V70.0056H87.0341V71.7957Z"
        fill="#3B3F44"
      />
      <path
        d="M78.8456 64.5451C80.2979 64.5451 81.5616 64.2316 82.5001 63.6383C83.5627 62.9665 84.1765 61.9726 84.2751 60.7638C84.2835 60.6606 84.293 60.5433 84.293 60.4249C84.293 60 84.2275 59.5873 84.0981 59.1977C83.5485 57.5432 81.8842 56.4979 79.5323 56.3306C79.2882 56.313 79.0637 56.3047 78.8456 56.3047H75.1094C71.8004 56.3047 69.6621 57.9219 69.6621 60.4249C69.6621 62.9278 71.8004 64.5451 75.1094 64.5451H78.8456ZM73.3084 60.4249C73.3084 59.6486 74.0771 59.1854 75.3649 59.1854H78.5903C79.8779 59.1854 80.6469 59.6486 80.6469 60.4249C80.6469 61.2011 79.8779 61.6643 78.5903 61.6643H75.3649C74.0771 61.6643 73.3084 61.2011 73.3084 60.4249Z"
        fill="#3B3F44"
      />
    </svg>
  );
};

export default React.memo(IcLogo);
