import React from "react";

const IcOutlink = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.980301 12.3887L-0.000244141 11.4081L9.61844 1.78949H0.793533V0.388672H11.9998V11.5949H10.599V2.77002L0.980301 12.3887Z"
        fill="#646D74"
      />
    </svg>
  );
};

export default React.memo(IcOutlink);
