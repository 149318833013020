import React from "react";

const IcHeaderLogo = () => {
  return (
    <svg
      width="112"
      height="72"
      viewBox="0 0 112 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_240_2619)">
        <path
          d="M89.0168 61.9008V49.7793H85.3542V54.2873H81.8277C82.4344 55.2028 82.7911 56.2538 82.7911 57.3818C82.7911 57.3854 82.7911 57.3927 82.7911 57.3964H85.3579V58.2753H82.6698C82.622 58.5024 82.5631 58.7294 82.4859 58.9491C82.1696 59.8646 81.6107 60.6923 80.8789 61.3844H85.3542V61.9044H89.0168V61.9008Z"
          fill="black"
        />
        <path
          d="M95.9455 39.3496H92.1836V47.3659H112.001V44.2092H95.9455V39.3496Z"
          fill="black"
        />
        <path
          d="M108.237 25.1445V27.6787H105.02V30.7878H108.237V32.0951H105.02V35.2042H108.237V41.5982H111.999V25.1445H108.237Z"
          fill="black"
        />
        <path
          d="M1.47852 15.3848V22.9103H20.1225V19.7536H5.24041V15.3848H1.47852Z"
          fill="black"
        />
        <path
          d="M20.1232 9.58634H23.4659V12.0143H32.4349V14.402H24.8228V17.5587H40.6831V23.0078H44.206V14.402H36.3439V12.0143H45.8056V8.97843H44.206V0.782714H24.8228V3.94308H40.6831V8.97843H24.2602V6.55414H20.1232V0.6875H16.3613V17.1412H20.1232V9.58634Z"
          fill="black"
        />
        <path
          d="M21.2657 25.584H17.9304V30.2714H5.08923V25.584H1.75391V41.5982H21.2694V25.584H21.2657ZM17.9304 38.3939H5.08923V33.6991H17.9341V38.3939H17.9304Z"
          fill="black"
        />
        <path
          d="M44.1839 41.8436V25.1445H40.6206V30.2714H38.9364V25.2654H35.373V41.5982H38.9364V33.6735H40.6206V41.8436H44.1839Z"
          fill="black"
        />
        <path
          d="M23.4473 30.5156V38.7992H34.5491V35.7926H27.0584V33.4526H33.7107V25.3887H23.4473V28.3952H30.0995V30.5156H23.4473Z"
          fill="black"
        />
        <path
          d="M65.0268 38.3937H49.6961V28.8137H65.0268V25.6094H46.3828V41.598H65.0268V38.3937Z"
          fill="black"
        />
        <path
          d="M28.7309 40.0859H24.969V44.1362H0V47.3661H66.1917V44.1362H28.7309V40.0859Z"
          fill="black"
        />
        <path
          d="M80.4701 44.1578H78.6976V28.9602H80.4701V25.7559H67.8128V28.9602H69.6845V44.1578H67.5664V47.3657H80.4701V44.1578ZM75.3476 44.1578H73.0346V28.9602H75.3476V44.1578Z"
          fill="black"
        />
        <path
          d="M108.238 67.4698H101.347V63.9505C104.186 63.2145 106.245 61.0575 106.245 58.505C106.245 57.0109 105.536 55.6559 104.381 54.6379H106.922V51.6094H101.255V49.4707H97.1734V51.6094H91.5067V54.6379H94.0477C92.893 55.6559 92.1833 57.0109 92.1833 58.505C92.1833 61.1637 94.4154 63.3902 97.4382 64.0311V67.4698H90.8926V70.6997H108.238V71.9998H112V49.7783H108.238V67.4698ZM95.7871 58.505C95.7871 56.967 97.3205 55.7182 99.2143 55.7182C101.108 55.7182 102.642 56.967 102.642 58.505C102.642 60.0431 101.108 61.2919 99.2143 61.2919C97.3205 61.2919 95.7871 60.0431 95.7871 58.505Z"
          fill="black"
        />
        <path
          d="M85.4943 64.4018H74.4256V62.6367H70.8145V72.0006H89.1017V62.6367H85.4906V64.4018H85.4943ZM85.4943 68.9428H74.4256V67.4633H85.4943V68.9428Z"
          fill="black"
        />
        <path
          d="M0.923006 8.60221C0.923006 11.9786 3.67364 14.7179 7.06412 14.7179C10.4546 14.7179 13.2052 11.9786 13.2052 8.60221C13.2052 7.13005 12.6831 5.78241 11.8152 4.72774H14.9483V1.79075H9.45438V0H5.49391V1.79075H0V4.72774H2.31303C1.44518 5.78241 0.923006 7.13005 0.923006 8.60221ZM7.06412 11.5831C5.40933 11.5831 4.07079 10.2501 4.07079 8.60221C4.07079 6.95427 5.40933 5.62128 7.06412 5.62128C8.71891 5.62128 10.0575 6.95427 10.0575 8.60221C10.0575 10.2501 8.71891 11.5831 7.06412 11.5831Z"
          fill="black"
        />
        <path
          d="M69.8322 57.0182C69.8322 59.838 72.5166 62.1232 75.8262 62.1232C76.9919 62.1232 78.0767 61.8339 78.9998 61.3431C80.228 60.6876 81.1583 59.6696 81.5739 58.4611C81.6879 58.1278 81.7651 57.7799 81.7982 57.4211C81.8092 57.2892 81.8203 57.1537 81.8203 57.0182C81.8203 56.0881 81.5224 55.2165 81.0112 54.4621C80.8237 54.1911 80.6104 53.9348 80.3714 53.6967H83.4383V50.7597H77.9444V49.0898H73.9839V50.7597H68.0781V53.6967H71.2811C70.3801 54.5903 69.8322 55.7475 69.8322 57.0182ZM75.8262 59.5048C74.2119 59.5048 72.9064 58.3915 72.9064 57.0182C72.9064 55.645 74.2156 54.5317 75.8262 54.5317C77.4369 54.5317 78.746 55.645 78.746 57.0182C78.746 58.3915 77.4369 59.5048 75.8262 59.5048Z"
          fill="black"
        />
        <path
          d="M100.877 28.8134V38.7377H103.918V25.448H98.1407C98.1407 25.448 98.0966 27.1069 97.9017 28.2458C97.0044 33.684 91.3229 35.4528 90.0212 35.6102C89.9954 35.6102 89.9256 35.6102 89.9256 35.6102V25.1367H86.3586V35.7421H84.9207V25.2612H81.3574V47.3618H84.9207V39.1478H86.3586V47.3618H89.9219V38.9098C89.9219 38.9098 100.196 38.4447 100.873 28.8134H100.877Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_240_2619">
          <rect width="112" height="72" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default React.memo(IcHeaderLogo);
