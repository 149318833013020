import React from "react";

const IcOutlinkM = ({ color }: { color: string }) => {
  return (
    <svg
      width="11"
      height="10"
      viewBox="0 0 11 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.41283 10L0.595703 9.18289L8.61128 1.16736H1.25719V0H10.5957V9.33853H9.42841V1.98447L1.41283 10Z"
        fill={color}
      />
    </svg>
  );
};

export default React.memo(IcOutlinkM);
