import { useState, useEffect } from "react";
import styled from "styled-components";
import { IcHeaderMobileLogo, IcMobileGnb, IcGnbClose } from "../../icons";
import GnbMobile from "./GnbMobile";

const HeaderMobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {}, [isOpen]);

  return (
    <StyledWrap isSelected={isOpen === false ? true : false}>
      <h1>
        <a href="/">
          <IcHeaderMobileLogo color={isOpen === false ? "black" : "White"} />
        </a>
      </h1>
      <button
        type="button"
        onClick={() => {
          setIsOpen((prev) => !prev);
        }}
      >
        {isOpen === false ? <IcMobileGnb /> : <IcGnbClose />}
      </button>
      {isOpen === true && <GnbMobile />}
    </StyledWrap>
  );
};

const StyledWrap = styled.header<{ isSelected: boolean }>`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 0 19px;
  box-sizing: border-box;
  background-color: ${({ isSelected }) => (isSelected ? "#f8f9fa" : "#202124")};
  z-index: 101;

  > h1 {
    height: 18px;
  }
`;

export default HeaderMobile;
