import React, { lazy, Suspense } from "react";
import { useRoutes } from "react-router-dom";
const COMPONENTS_MAP: Map<
  string,
  React.LazyExoticComponent<React.ComponentType>
> = new Map();
const getCachedLazyComponent = (key: string) => {
  if (COMPONENTS_MAP.has(key)) return COMPONENTS_MAP.get(key);
  const LazyComponent = lazy<React.ComponentType>(() =>
    import(`./pages/${key}/Wrap.Container`).then((m) => m)
  );
  COMPONENTS_MAP.set(key, LazyComponent);
  return LazyComponent;
};
const MainContainer = getCachedLazyComponent("main");
const IntroduceContainer = getCachedLazyComponent("introduce");
const BusinessContainer = getCachedLazyComponent("business");
const NoticeContainer = getCachedLazyComponent("notice");
const MemberNewsContainer = getCachedLazyComponent("memberNews");
const MemberListContainer = getCachedLazyComponent("memberList");
const NewsContainer = getCachedLazyComponent("news");
const NoticeViewContainer = getCachedLazyComponent("noticeView");
const PressContainer = getCachedLazyComponent("press");
const PressViewContainer = getCachedLazyComponent("pressView");

const PageRoutes = () => {
  const element = useRoutes([
    {
      path: "/",
      element: (
        <Suspense fallback={<div></div>}>
          {MainContainer && <MainContainer />}
        </Suspense>
      ),
    },

    {
      path: "/introduce",
      element: (
        <Suspense fallback={<div></div>}>
          {IntroduceContainer && <IntroduceContainer />}
        </Suspense>
      ),
    },

    {
      path: "/business",
      element: (
        <Suspense fallback={<div></div>}>
          {BusinessContainer && <BusinessContainer />}
        </Suspense>
      ),
    },

    {
      path: "/notice",
      element: (
        <Suspense fallback={<div></div>}>
          {NoticeContainer && <NoticeContainer />}
        </Suspense>
      ),
    },

    {
      path: "/memberNews",
      element: (
        <Suspense fallback={<div></div>}>
          {MemberNewsContainer && <MemberNewsContainer />}
        </Suspense>
      ),
    },

    {
      path: "/memberList",
      element: (
        <Suspense fallback={<div></div>}>
          {MemberListContainer && <MemberListContainer />}
        </Suspense>
      ),
    },

    {
      path: "/noticeView/:articleId",
      element: (
        <Suspense fallback={<div></div>}>
          {NoticeViewContainer && <NoticeViewContainer />}
        </Suspense>
      ),
    },

    {
      path: "/news",
      element: (
        <Suspense fallback={<div></div>}>
          {NewsContainer && <NewsContainer />}
        </Suspense>
      ),
    },

    {
      path: "/press",
      element: (
        <Suspense fallback={<div></div>}>
          {PressContainer && <PressContainer />}
        </Suspense>
      ),
    },

    {
      path: "/pressView/:articleId",
      element: (
        <Suspense fallback={<div></div>}>
          {PressViewContainer && <PressViewContainer />}
        </Suspense>
      ),
    },
  ]);
  return element;
};
export default PageRoutes;
