import React from "react";

const IcHeaderMobileLogo = ({ color }: { color: string }) => {
  return (
    <svg
      width="142"
      height="16"
      viewBox="0 0 142 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_240_3047)">
        <path
          d="M3.59504 10.7871H1.01562V15.9997H13.8044V13.8135H3.59504V10.7871Z"
          fill={color}
        />
        <path
          d="M30.3275 0.804023H17.0293V2.96231H27.9126V6.40681H16.6442V4.74628H13.804V0.736328H11.2246V11.9897H13.804V6.82493H16.0986V8.48147H22.2523V10.1141H17.0293V12.2764H27.9126V15.9996H30.3275V10.1141H24.936V8.48147H31.4267V6.40681H30.3275V0.804023Z"
          fill={color}
        />
        <path
          d="M4.84592 10.3293C7.17261 10.3293 9.05803 8.45774 9.05803 6.14813C9.05803 5.14067 8.701 4.22081 8.10328 3.50005H10.2535V1.4891H6.48263V0.266602H3.76682V1.49308H0V3.50005H1.58455C0.986835 4.22081 0.62981 5.14465 0.62981 6.14813C0.62981 8.45774 2.51523 10.3293 4.84191 10.3293H4.84592ZM4.84592 4.10931C5.98118 4.10931 6.89982 5.02121 6.89982 6.14813C6.89982 7.27506 5.98118 8.18696 4.84592 8.18696C3.71066 8.18696 2.79202 7.27506 2.79202 6.14813C2.79202 5.02121 3.71066 4.10931 4.84592 4.10931Z"
          fill={color}
        />
        <path
          d="M121.533 5.78956V5.79752H123.33V6.41076H121.449C121.417 6.57005 121.373 6.72933 121.321 6.88065C121.1 7.52176 120.707 8.09917 120.193 8.581H123.33V8.94337H125.898V0.481445H123.33V3.62729H120.859C121.284 4.26442 121.533 5.00111 121.533 5.78956Z"
          fill={color}
        />
        <path
          d="M139.364 0.481634V12.838H134.534V10.3811C136.524 9.8674 137.964 8.35819 137.964 6.5782C137.964 5.53489 137.467 4.58716 136.656 3.87437H138.437V1.75988H134.466V0.266602H131.606V1.75988H127.634V3.87437H129.415C128.605 4.58716 128.108 5.53091 128.108 6.5782C128.108 8.43385 129.672 9.99084 131.79 10.4368V12.838H127.205V15.0959H139.36V16.0038H141.996V0.481634H139.36H139.364ZM130.639 6.5782C130.639 5.50304 131.714 4.63096 133.038 4.63096C134.362 4.63096 135.437 5.50304 135.437 6.5782C135.437 7.65336 134.362 8.52544 133.038 8.52544C131.714 8.52544 130.639 7.65336 130.639 6.5782Z"
          fill={color}
        />
        <path
          d="M123.366 10.6954H115.611V9.46094H113.08V16.0035H125.893V9.46094H123.362V10.6954H123.366ZM123.366 13.8651H115.611V12.8298H123.366V13.8651Z"
          fill={color}
        />
        <path
          d="M116.65 9.09906C117.469 9.09906 118.227 8.89597 118.873 8.55351C119.735 8.09557 120.385 7.38278 120.678 6.53858C120.758 6.30762 120.81 6.06471 120.834 5.81384C120.842 5.72225 120.85 5.62668 120.85 5.53111C120.85 4.88203 120.642 4.27277 120.281 3.74714C120.152 3.556 120 3.3768 119.831 3.21354H121.982V1.16675H118.131V0H115.359V1.16675H111.223V3.21752H113.465C112.835 3.84271 112.45 4.65107 112.45 5.53907C112.45 7.50622 114.332 9.10304 116.65 9.10304V9.09906ZM116.65 3.79891C117.782 3.79891 118.696 4.57541 118.696 5.53509C118.696 6.49477 117.782 7.27128 116.65 7.27128C115.519 7.27128 114.604 6.49477 114.604 5.53509C114.604 4.57541 115.519 3.79891 116.65 3.79891Z"
          fill={color}
        />
        <path
          d="M99.2293 10.4014H96.5938V16.0002H110.478V13.7941H99.2293V10.4014Z"
          fill={color}
        />
        <path
          d="M107.842 2.24956H105.588V4.4198H107.842V5.33568H105.588V7.50591H107.842V11.9738H110.478V0.477539H107.842V2.24956Z"
          fill={color}
        />
        <path
          d="M46.9037 0.788086H44.569V4.06136H35.5671V0.788086H33.2324V11.9738H46.9037V0.788086ZM44.569 9.73582H35.5671V6.45857H44.569V9.73582Z"
          fill={color}
        />
        <path
          d="M59.2824 6.43871H60.4618V12.145H62.961V0.477539H60.4618V4.06141H59.2824V0.565145H56.7832V11.9738H59.2824V6.43871Z"
          fill={color}
        />
        <path
          d="M56.2101 7.91671H50.959V6.28406H55.6204V0.649414H48.4277V2.75195H53.0891V4.23329H48.4277V10.0192H56.2101V7.91671Z"
          fill={color}
        />
        <path
          d="M77.5635 9.73251H66.8206V3.04262H77.5635V0.804688H64.502V11.9744H77.5635V9.73251Z"
          fill={color}
        />
        <path
          d="M52.1298 10.915H49.4943V13.7423H32V16.0002H78.3813V13.7423H52.1298V10.915Z"
          fill={color}
        />
        <path
          d="M79.5162 3.14613H80.828V13.7584H79.3438V15.9963H88.3857V13.7584H87.1422V3.14613H88.3857V0.908203H79.5162V3.14613ZM83.1748 3.14613H84.7954V13.7584H83.1748V3.14613Z"
          fill={color}
        />
        <path
          d="M102.683 3.042V9.9748H104.813V0.692571H100.766C100.766 0.692571 100.734 1.85136 100.601 2.64379C99.9714 6.4427 95.992 7.67714 95.0774 7.78864C95.0613 7.78864 95.0092 7.78864 95.0092 7.78864V0.477539H92.51V7.88421H91.5031V0.565145H89.0039V15.9997H91.5031V10.2615H92.51V15.9997H95.0092V10.0943C95.0092 10.0943 102.21 9.76773 102.683 3.042Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_240_3047">
          <rect width="142" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default React.memo(IcHeaderMobileLogo);
