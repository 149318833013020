import React from "react";

const IcGnbClose = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="2.20711"
        y1="0.792893"
        x2="20.2071"
        y2="18.7929"
        stroke="white"
        strokeWidth="2"
      />
      <line
        x1="0.792893"
        y1="18.7929"
        x2="18.7929"
        y2="0.792891"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

export default React.memo(IcGnbClose);
