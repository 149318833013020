import React from "react";

const IcMobileGnb = () => {
  return (
    <svg
      width="28"
      height="18"
      viewBox="0 0 28 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line y1="1" x2="28" y2="1" stroke="#3B3F44" strokeWidth="2" />
      <line y1="9" x2="28" y2="9" stroke="#3B3F44" strokeWidth="2" />
      <line y1="17" x2="28" y2="17" stroke="#3B3F44" strokeWidth="2" />
    </svg>
  );
};

export default React.memo(IcMobileGnb);
