import React from "react";
import { GlobalStyle } from "./styles/global-styles";
import { BrowserRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import PageRoutes from "./PageRoutes";
import { HelmetProvider } from "react-helmet-async";
import { Desktop, Mobile } from "./utils/mediaQuery";
import Header from "./pages/common/Header";
import HeaderMobile from "./pages/common/HeaderMobile";
import Footer from "./pages/common/Footer";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 60 * 1_000,
      cacheTime: 300 * 1_000,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
    },
  },
});

function App() {
  return (
    <>
      <HelmetProvider>
        <GlobalStyle />

        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <Desktop>
                <Header />
              </Desktop>
              <Mobile>
                <HeaderMobile />
              </Mobile>
              <PageRoutes />
              <Footer />
            </QueryParamProvider>
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </HelmetProvider>
    </>
  );
}

export default App;
